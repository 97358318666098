var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      staticClass:
        "gx-card-widget gx-card-widget gx-card-full gx-p-3 gx-bg-red gx-text-white",
      class: [{ "gx-bg-cyan": _vm.sub && _vm.sub.active }]
    },
    [
      _c(
        "div",
        { staticClass: "gx-media gx-align-items-center gx-flex-nowrap" },
        [
          _c(
            "div",
            { staticClass: "gx-mr-2 gx-mr-xxl-3" },
            [
              _c("a-icon", {
                staticClass: "gx-fs-icon-lg",
                attrs: { type: "euro" }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "gx-media-body" },
            [
              _c(
                "a-row",
                { attrs: { type: "flex" } },
                [
                  _vm.sub && _vm.sub.active
                    ? _c("a-col", { attrs: { span: 24 } }, [
                        _c("h4", { staticClass: "gx-text-white" }, [
                          _vm._v(
                            "\n            Great! You have an active subscription\n          "
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n            Your current Plan supports:\n            "
                          ),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.sub.members) + " Members")
                          ]),
                          _c(
                            "strong",
                            [
                              _vm._v(_vm._s(_vm.sub.admins) + " Club Admin"),
                              _vm.sub.admins > 1 ? [_vm._v("s")] : _vm._e()
                            ],
                            2
                          )
                        ]),
                        _c("p", { staticStyle: { padding: "0" } }, [
                          _vm._v(
                            "\n            Renew date:\n            " +
                              _vm._s(
                                _vm.nUnixTime(_vm.sub.current_period_end)
                              ) +
                              "\n          "
                          )
                        ])
                      ])
                    : _c("a-col", { attrs: { span: 24 } }, [
                        _c(
                          "h4",
                          {
                            staticClass: "gx-text-white",
                            staticStyle: { "margin-bottom": "0" }
                          },
                          [
                            _vm._v(
                              "\n            You currently have no subscription, please upgrade to start using\n            SubsApp\n          "
                            )
                          ]
                        )
                      ]),
                  _c(
                    "a-col",
                    { staticClass: "gx-pt-3", attrs: { span: 24 } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.openUpgradeModal }
                        },
                        [
                          _vm._v(
                            "\n            Select Subscription\n          "
                          )
                        ]
                      ),
                      _c("a-button", { attrs: { type: "danger" } }, [
                        _vm._v("\n            Cancel\n          ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("subscription-upgrade-modal", {
        attrs: { visible: _vm.upgradeModal },
        on: { close: _vm.closeUpgradeModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }