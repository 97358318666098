var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-container" }, [
    _c("div", {}, [
      _vm.image
        ? _c("div", { staticClass: "error-icon" }, [
            _c("img", { attrs: { src: _vm.image } })
          ])
        : _vm._e(),
      _c("div", { staticClass: "error-body" }, [
        _c("div", { staticClass: "error-text" }, [
          _vm._v("\n        " + _vm._s(_vm.warning) + "\n      ")
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }