var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "Subscription Plans", width: "1200px" },
      on: { cancel: _vm.close, ok: _vm.close },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: 10 } },
        [
          _c("a-col", { attrs: { xs: 24, sm: 24, md: 6, lg: 6 } }, [
            _c("div", { staticClass: "gx-package" }, [
              _c(
                "div",
                {
                  staticClass: "gx-package-header gx-bg-primary gx-text-white"
                },
                [
                  _c("h2", { staticClass: "gx-price" }, [
                    _c("i", { staticClass: "icon icon-halfstar" }),
                    _vm._v("Free Plan\n          ")
                  ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0"
                    },
                    [_c("span", [_vm._v("€0.00/month")])]
                  )
                ]
              ),
              _c("div", { staticClass: "gx-package-body" }, [
                _c("ul", { staticClass: "gx-package-items" }, [
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "user" } }),
                      _vm._v(
                        "\n              Up to 20 Club Members\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "team" } }),
                      _vm._v(
                        "\n              1 Club Administrator\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "euro" } }),
                      _vm._v(
                        "\n              2% + €0.30 Transaction processing rate\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "safety-certificate" } }),
                      _vm._v("\n              Monthly Payouts\n            ")
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "gx-package-footer" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.attemptUpgrade("plan_F90t4jMmr704Up")
                          }
                        }
                      },
                      [_vm._v("\n              Select Plan\n            ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("a-col", { attrs: { xs: 24, sm: 24, md: 6, lg: 6 } }, [
            _c("div", { staticClass: "gx-package" }, [
              _c(
                "div",
                { staticClass: "gx-package-header gx-bg-cyan gx-text-white" },
                [
                  _c("h2", { staticClass: "gx-price" }, [
                    _c("i", { staticClass: "icon icon-halfstar" }),
                    _vm._v("Silver")
                  ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0"
                    },
                    [_c("span", [_vm._v("€19.99/month")])]
                  )
                ]
              ),
              _c("div", { staticClass: "gx-package-body" }, [
                _c("ul", { staticClass: "gx-package-items" }, [
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "user" } }),
                      _vm._v(
                        "\n              Up to 200 Club Members\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "team" } }),
                      _vm._v(
                        "\n              10 Club Administrators\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "euro" } }),
                      _vm._v(
                        "\n              2% + €0.30 Transaction processing rate\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "safety-certificate" } }),
                      _vm._v("\n              Monthly Payouts\n            ")
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "gx-package-footer" },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "gx-btn-cyan",
                        on: {
                          click: function($event) {
                            return _vm.attemptUpgrade("plan_F90uT88HPbLzYO")
                          }
                        }
                      },
                      [_vm._v("\n              Select Plan\n            ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("a-col", { attrs: { xs: 24, sm: 24, md: 6, lg: 6 } }, [
            _c("div", { staticClass: "gx-package" }, [
              _c(
                "div",
                { staticClass: "gx-package-header gx-bg-cyan gx-text-white" },
                [
                  _c("h2", { staticClass: "gx-price" }, [
                    _c("i", { staticClass: "icon icon-halfstar" }),
                    _vm._v("Gold")
                  ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0"
                    },
                    [_c("span", [_vm._v("€39.99/month")])]
                  )
                ]
              ),
              _c("div", { staticClass: "gx-package-body" }, [
                _c("ul", { staticClass: "gx-package-items" }, [
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "user" } }),
                      _vm._v(
                        "\n              Up to 500 Club Members\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "team" } }),
                      _vm._v(
                        "\n              Unlimited Club Administrators\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "euro" } }),
                      _vm._v(
                        "\n              2% + €0.30 Transaction processing rate\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "safety-certificate" } }),
                      _vm._v("\n              Monthly Payouts\n            ")
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "gx-package-footer" },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "gx-btn-cyan",
                        on: {
                          click: function($event) {
                            return _vm.attemptUpgrade("plan_F90uIZHiMeBaVm")
                          }
                        }
                      },
                      [_vm._v("\n              Select Plan\n            ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("a-col", { attrs: { xs: 24, sm: 24, md: 6, lg: 6 } }, [
            _c("div", { staticClass: "gx-package" }, [
              _c(
                "div",
                {
                  staticClass: "gx-package-header gx-bg-primary gx-text-white"
                },
                [
                  _c("h2", { staticClass: "gx-price" }, [
                    _c("i", { staticClass: "icon icon-halfstar" }),
                    _vm._v("Unlimited\n          ")
                  ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0"
                    },
                    [_c("span", [_vm._v("Contact Us!")])]
                  )
                ]
              ),
              _c("div", { staticClass: "gx-package-body" }, [
                _c("ul", { staticClass: "gx-package-items" }, [
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "user" } }),
                      _vm._v(
                        "\n              Unlimited Club Members\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "team" } }),
                      _vm._v(
                        "\n              Unlimited Club Administrators\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "euro" } }),
                      _vm._v(
                        "\n              Tailored transaction fees\n            "
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("a-icon", { attrs: { type: "safety-certificate" } }),
                      _vm._v("\n              Tailored Payouts\n            ")
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "gx-package-footer" }, [
                  _c(
                    "a",
                    { attrs: { href: "mailto:info@subsapp.com" } },
                    [
                      _c("a-button", { attrs: { type: "primary" } }, [
                        _vm._v("\n                Contact Us\n              ")
                      ])
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }