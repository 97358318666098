<template>
  <a-modal
    v-model="visible"
    title="Subscription Plans"
    width="1200px"
    @cancel="close"
    @ok="close"
  >
    <a-row type="flex" :gutter="10">
      <a-col :xs="24" :sm="24" :md="6" :lg="6">
        <div class="gx-package">
          <div class="gx-package-header gx-bg-primary gx-text-white">
            <h2 class="gx-price">
              <i class="icon icon-halfstar"></i>Free Plan
            </h2>
            <p
              class="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0"
            >
              <span>€0.00/month</span>
            </p>
          </div>
          <div class="gx-package-body">
            <ul class="gx-package-items">
              <li>
                <a-icon type="user" />
                Up to 20 Club Members
              </li>
              <li>
                <a-icon type="team" />
                1 Club Administrator
              </li>
              <li>
                <a-icon type="euro" />
                2% + €0.30 Transaction processing rate
              </li>
              <li>
                <a-icon type="safety-certificate" />
                Monthly Payouts
              </li>
            </ul>
            <div class="gx-package-footer">
              <!--<a-button-->
                <!--type="primary"-->
                <!--@click="attemptUpgrade('stripe_plan_free')">-->
                <!--Select Plan-->
              <!--</a-button>-->
              <a-button
                type="primary"
                @click="attemptUpgrade('plan_F90t4jMmr704Up')">
                Select Plan
              </a-button>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="6" :lg="6">
        <div class="gx-package">
          <div class="gx-package-header gx-bg-cyan gx-text-white">
            <h2 class="gx-price"><i class="icon icon-halfstar"></i>Silver</h2>
            <p class="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0">
              <span>€19.99/month</span>
            </p>
          </div>
          <div class="gx-package-body">
            <ul class="gx-package-items">
              <li>
                <a-icon type="user" />
                Up to 200 Club Members
              </li>
              <li>
                <a-icon type="team" />
                10 Club Administrators
              </li>
              <li>
                <a-icon type="euro" />
                2% + €0.30 Transaction processing rate
              </li>
              <li>
                <a-icon type="safety-certificate" />
                Monthly Payouts
              </li>
            </ul>
            <div class="gx-package-footer">
              <a-button
                class="gx-btn-cyan"
                @click="attemptUpgrade('plan_F90uT88HPbLzYO')"
              >
                Select Plan
              </a-button>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="6" :lg="6">
        <div class="gx-package">
          <div class="gx-package-header gx-bg-cyan gx-text-white">
            <h2 class="gx-price"><i class="icon icon-halfstar"></i>Gold</h2>
            <p
              class="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0"
            >
              <span>€39.99/month</span>
            </p>
          </div>
          <div class="gx-package-body">
            <ul class="gx-package-items">
              <li>
                <a-icon type="user" />
                Up to 500 Club Members
              </li>
              <li>
                <a-icon type="team" />
                Unlimited Club Administrators
              </li>
              <li>
                <a-icon type="euro" />
                2% + €0.30 Transaction processing rate
              </li>
              <li>
                <a-icon type="safety-certificate" />
                Monthly Payouts
              </li>
            </ul>
            <div class="gx-package-footer">
              <a-button
                class="gx-btn-cyan"
                @click="attemptUpgrade('plan_F90uIZHiMeBaVm')"
              >
                Select Plan
              </a-button>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="6" :lg="6">
        <div class="gx-package">
          <div class="gx-package-header gx-bg-primary gx-text-white">
            <h2 class="gx-price">
              <i class="icon icon-halfstar"></i>Unlimited
            </h2>
            <p
              class="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0"
            >
              <span>Contact Us!</span>
            </p>
          </div>
          <div class="gx-package-body">
            <ul class="gx-package-items">
              <li>
                <a-icon type="user" />
                Unlimited Club Members
              </li>
              <li>
                <a-icon type="team" />
                Unlimited Club Administrators
              </li>
              <li>
                <a-icon type="euro" />
                Tailored transaction fees
              </li>
              <li>
                <a-icon type="safety-certificate" />
                Tailored Payouts
              </li>
            </ul>
            <div class="gx-package-footer">
              <a href="mailto:info@subsapp.com">
                <a-button type="primary">
                  Contact Us
                </a-button>
              </a>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <div slot="footer" />
  </a-modal>
</template>

<script>
import nTime from "@/mixins/time";
import nCurrency from "@/mixins/currency";
import { billingService } from "@/common/api/api.service";
import notifications from "@/common/notifications/notification.service";

export default {
  name: "SubscriptionUpgradeModal",
  mixins: [nTime, nCurrency],
  props: {
    visible: {
      default: false,
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    attemptUpgrade(plan) {
      billingService
        .attemptUpgrade({
          planId: plan
        })
        .then(resp => {
          if (resp.data.success) {
            this.close();
            notifications.success("Successfully upgraded your account.");
          } else {
            notifications.warn(
              "We could not upgrade your account, please ensure your account has a valid card or contact support."
            );
          }
        });
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
